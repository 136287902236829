.background-blue {
  background-color: #352990;
}

/* .background-blue {
  background-color: #2ba8f9;
} */

.btn-hover {
  /* width: 200px;
  font-size: 16px;
  font-weight: 600; */
  color: #fff;

  /* cursor: pointer; */
  /* margin: 20px;
  height: 55px; */
  text-align: center;
  border: none;
  background-size: 300% 100%;

  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;

  /* border-radius: 50px; */
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
  box-shadow: none;
}

.btn-hover.color-4 {
  background-image: linear-gradient(
    to right,
    #d86f7a,
    #df6672 /* #1b87d2,
    #2ba8f9,
    #1b87d2,
    #1b87d2,
    #1b87d2 */
  );
  /* box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75); */
  box-shadow: none;
}

.btn-hover.color-3 {
  background-image: linear-gradient(
    to right,
    #2ba8f9,
    #2ba8f9 /* #1b87d2,
    #2ba8f9,
    #1b87d2,
    #1b87d2,
    #1b87d2 */
  );
  /* box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75); */
  box-shadow: none;
}

.btn-hover.color-2 {
  background-image: linear-gradient(
    to right,
    #bd8aa5,
    #bb82a3 /* #1b87d2,
    #2ba8f9,
    #1b87d2,
    #1b87d2,
    #1b87d2 */
  );
  /* box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75); */
  box-shadow: none;
}

.btn-hover.color-1 {
  background-image: linear-gradient(
    to right,
    #9d85c8,
    #9d85c8 /* #1b87d2,
    #2ba8f9,
    #1b87d2,
    #1b87d2,
    #1b87d2 */
  );
  /* box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75); */
  /* box-shadow: none; */
  /* border: "#352990"; */
}

.btn-hover.color-old {
  background-image: linear-gradient(
    to right,
    #4e459e,
    #352990,
    #6357ca,
    #4837c4
  );
  /* box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75); */
  box-shadow: none;
}

.btn-hover.color-1 {
  color: red;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.inputText:focus {
  outline: none !important;
  box-shadow: none !important;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* .double-border:before {
  background: none;
  border: 4px solid #fff;
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  pointer-events: none;
} */

.rc-tooltip {
  z-index: 1500;
}
.rc-slider-tooltip {
  z-index: 1500;
}

#tsparticles {
  width: 100%;
  height: 100%;
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
}

.menuButton {
  background: none;
  border: none;
  color: #b3ffb3;
}
.menuButton:hover {
  text-decoration: underline;
  color: yellow;
}

.my-form-control:focus {
  border: "solid red 2px";
}
